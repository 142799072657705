import { default as indexm4G26460tZMeta } from "/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/index.vue?macro=true";
import { default as _91kit_id_93kpnrOOuu3NMeta } from "/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/kit/[kit_id].vue?macro=true";
import { default as index9V0hGDqxxcMeta } from "/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/kit/index.vue?macro=true";
import { default as indexzWI2SjPKkTMeta } from "/var/www/app.conexsolution.com/releases/31/pages/access-code/index.vue?macro=true";
import { default as _91access_code_93kWAgVkE2xNMeta } from "/var/www/app.conexsolution.com/releases/31/pages/check-connectivity/[access_code].vue?macro=true";
import { default as indexZT2coj5dX1Meta } from "/var/www/app.conexsolution.com/releases/31/pages/check-connectivity/index.vue?macro=true";
import { default as indexrZ0VRQiJ4UMeta } from "/var/www/app.conexsolution.com/releases/31/pages/create-survey/index.vue?macro=true";
import { default as indexTveNHBJzbSMeta } from "/var/www/app.conexsolution.com/releases/31/pages/index.vue?macro=true";
export default [
  {
    name: "access-code-access_code",
    path: "/access-code/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/index.vue").then(m => m.default || m)
  },
  {
    name: "access-code-access_code-kit-kit_id",
    path: "/access-code/:access_code()/kit/:kit_id()",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/kit/[kit_id].vue").then(m => m.default || m)
  },
  {
    name: "access-code-access_code-kit",
    path: "/access-code/:access_code()/kit",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/access-code/[access_code]/kit/index.vue").then(m => m.default || m)
  },
  {
    name: "access-code",
    path: "/access-code",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/access-code/index.vue").then(m => m.default || m)
  },
  {
    name: "check-connectivity-access_code",
    path: "/check-connectivity/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/check-connectivity/[access_code].vue").then(m => m.default || m)
  },
  {
    name: "check-connectivity",
    path: "/check-connectivity",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/check-connectivity/index.vue").then(m => m.default || m)
  },
  {
    name: "create-survey",
    path: "/create-survey",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/create-survey/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/app.conexsolution.com/releases/31/pages/index.vue").then(m => m.default || m)
  }
]