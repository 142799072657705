import {defineNuxtPlugin} from "nuxt/app";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {

    const options = {
        // You can set your default options here
        position: POSITION.BOTTOM_CENTER
    };

    nuxtApp.vueApp.use(Toast, options);
})