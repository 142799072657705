<template>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
</template>

<style>

</style>
<script setup lang="ts">
useHead({
  script: [
    { src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBt9OsQmBMuE1jGW9XAQ234RYFoxu7WITk&libraries=places', defer: true },
  ]
})
</script>